<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <v-layout justify-end class="mb-2">
        <v-btn color="primary" @click="onGoToEmployeeCreate">
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          {{ $t("employee") }}
        </v-btn>
      </v-layout>
      <v-layout justify-space-between class="mb-3">
        <v-layout class="col-8 pa-0">
          <v-text-field
            v-model="objSearch"
            outlined
            dense
            class="mr-8"
            hide-details
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="`${$t('place_holders.name')}/${$t('code')}/${$t('employee_phone')}`"
            @keydown.enter="onClickSearch"
          />
          <v-autocomplete
            v-if="showPostOffice"
            v-model="selectPostOffice"
            outlined
            dense
            clearable
            hide-details
            :filter="filterItems"
            class="mr-2 col-3"
            :no-data-text="$t('no-data')"
            :placeholder="$t('place_holders.choice-post-office')"
            :items="lstPostOffice"
            item-text="name"
            item-value="id"
          />
          <v-select
            v-model="regencySelected"
            outlined
            dense
            hide-details
            class="col-3"
            clearable
            :placeholder="$t('place_holders.regency')"
            :items="regencyItems"
            item-text="name"
            item-value="code"
            multiple
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="toggleStatusSearch"
              >
                <v-list-item-action>
                  <v-icon :color="regencySelected.length > 0 ? 'primary' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("all") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-layout>
        <v-btn color="primary" class="col-1" @click="onClickSearch">
          {{ $t("search") }}
        </v-btn>
      </v-layout>
      <v-data-table
        class="border-primary"
        hide-default-footer
        :no-data-text="$t('not_found')"
        :headers="headers"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items="items"
        @click:row="viewDetail"
      >
        <template v-slot:[`item.name`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.name }}
          </td>
        </template>
        <template v-slot:[`item.code`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.code }}
          </td>
        </template>
        <template v-slot:[`item.referralCode`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.roles.includes("ROLE_SALE") ? item.code : '' }}
          </td>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ getTextTruncate(item.email, 30) }}
          </td>
        </template>
        <template v-slot:[`item.postOffice`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.poName }}
          </td>
        </template>
        <template v-slot:[`item.roles`]="{ item }">
          <v-layout>
            <div v-for="(it, index) in item.roles" :key="index" class="mr-2">
              <v-chip
                v-if="it === 'ROLE_ROOT' || it === 'ROLE_ADMIN' || it === 'ROLE_SALE' || it === 'ROLE_CUSTOMER_CARE' || it === 'ROLE_ACCOUNTANT'"
                color="#CCF3FE"
              >
                {{ displayRole(it) }}
              </v-chip>
            </div>
          </v-layout>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ getTextStatus(item.status) }}
          </td>
        </template>
        <template v-slot:footer>
          <custom-pagination
            :page="page"
            :start-record="computedStartRecord"
            :end-record="computedEndRecord"
            :total-record="totalRecord"
            :total-page="totalPage"
            :on-change-page="onChangePage"
          />
        </template>
      </v-data-table>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import CustomPagination from "@/components/CustomPagination.vue";
import constants from "@/constants";
import filterItems from "@/helpers/filterItems";
import { StringUtils } from "@/helpers/stringUtils";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { UserService } from "@/services/userService";
import { DataUtils } from "@/helpers/dataUtils";
import { StorageService } from "@/services/storageService";
import routePaths from "@/router/routePaths";
import { mapActions } from "vuex";
import { DataBreadCrumbUtils } from "@/helpers/dataBreadCrumbUtils";
import { PostService } from "@/services/postService";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    CustomPagination,
    Breadscrumbs
  },
  mixins: [filterItems],
  data() {
    return {
      objSearch: "",
      regencySelected: "",
      regencyItems: [],
      items: [],
      totalPage: 0,
      page: 1,
      totalRecord: 0,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      showLoading: false,
      showConfirmTokenExpried: false,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      currentPoId: JSON.parse(localStorage.getItem("CURRENT_USER")).poId,
      breadcrumbs: [
        {
          text: 'employee_list',
          disabled: true,
          isActive: true,
        },
      ],
      btnSearch: false,
      lstPostOffice: [],
      selectPostOffice: null
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("employee_name"),
          value: "name",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("employee_code"),
          value: "code",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("referral_code"),
          value: "referralCode",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("sdt"),
          value: "phone",
          sortable: false,
          class: "text-subtitle-2",
        },
        { text: this.$t("email"), value: "email", class: "text-subtitle-2" },
        {
          text: this.$t("post_offices"),
          value: "postOffice",
          sortable: false,
          class: "text-subtitle-2",
        },
        { text: this.$t("regency"), value: "roles", class: "text-subtitle-2" },
        {
          text: this.$t('registed_date'),
          value: 'createdAt',
          width: '10%',
          class: 'text-subtitle-2' },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "text-subtitle-2",
        },
      ]
    },
    computedStartRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.numberEmployeesPerPage + 1;
      } else {
        return 0;
      }
    },
    computedEndRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.numberEmployeesPerPage + this.items.length;
      } else {
        return (this.page - 1) * constants.numberEmployeesPerPage;
      }
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
    likesAllStatus() {
      return this.regencySelected.length === this.regencyItems.length;
    },
    likesSomeStatus() {
      return this.regencySelected.length > 0 && !this.likesAllStatus;
    },
    icon() {
      if (this.likesAllStatus) return "mdi-close-box";
      if (this.likesSomeStatus) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    showPostOffice() {
      return this.currentRole.includes("ROLE_ADMIN") && this.currentPoId === constants.postOffice.primary
    },
    showRoleSale() {
      return this.currentRole.includes("ROLE_SALE")
    }
  },
  watch: {},
  created() {
    if (window.location.pathname && !this.currentRole.includes("ROLE_ADMIN") && !this.currentRole.includes("ROLE_ROOT")) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    ...mapActions("layout", ["setBreadcrumbs"]),
    async initData() {
      await this.getRoles();
      this.searchPostOffice()
      this.search();
    },
    async search() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      const { status, data } = await UserService.searchEmployee(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.totalRecord = data.totalRecord;
        this.totalPage = DataUtils.calculateEmployeePage(data.totalRecord);
        if (data.totalRecord > 0) {
          this.items = data.data;
        } else {
          this.items = [];
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestPars() {
      const pars = {
        currentPage: this.page,
        limit: constants.numberEmployeesPerPage,
        objSearch: "",
      };
      if (this.selectPostOffice) {
        pars["poId"] = this.selectPostOffice
      }

      if (this.objSearch) {
        pars.objSearch = this.objSearch.trim();
      }

      if (this.regencySelected.length) {
        pars["roles"] = this.regencySelected;
      }

      return pars;
    },
    async getRoles() {
      this.showLoading = true;
      const { status, data } = await UserService.getRoles();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.regencyItems = data;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    async searchPostOffice() {
      this.showLoading = true;
      const pars = this.bindRequestParsOffice();
      const { status, data } = await PostService.getPostOffice(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        if (data.totalRecord > 0) {
          this.lstPostOffice = data.data;
        } else {
          this.lstPostOffice = [];
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestParsOffice() {
      const pars = {
        keyword: "",
        status: 1,
        limit: 1000,
        currentPage: this.page,
      }
      return pars
    },
    getTextTruncate(val, size) {
      if (val.length > size) {
        return val.substring(0, size) + "...";
      } else {
        return val;
      }
    },
    toggleStatusSearch() {
      this.$nextTick(() => {
        if (this.likesAllStatus) {
          this.regencySelected = [];
        } else {
          this.regencySelected = this.bindStatusCodes(this.regencyItems);
        }
      });
    },
    bindStatusCodes(items) {
      const results = [];
      items.forEach((item) => {
        results.push(item.code);
      });
      return results;
    },
    onClickSearch() {
      this.page = 1;
      this.btnSearch = true
      this.search();
    },
    onChangePage(page) {
      this.page = page;
      if (this.btnSearch === true) {
        this.search();
      } else {
        this.objSearch = ''
        this.regencySelected = [];
        this.search();
      }
    },
    displayRole(val) {
      if (val === constants.roleRoot) {
        return "Admin root";
      }
      if (val === constants.roleAdmin) {
        return "Admin";
      }
      if (val === constants.roleSale) {
        return "Sale";
      }
      if (val === constants.roleCustomerCare) {
        return "CSKH";
      }
      if (val === constants.roleAccountant) {
        return this.$t("role_accountant");
      }
    },
    getTextStatus(item) {
      if (item === constants.statusCustomer.active) {
        return this.$t("actived");
      } else if (item === constants.statusCustomer.delete) {
        return this.$t("locked");
      } else if (item === constants.statusCustomer.inactive) {
        return this.$t("off");
      }
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate);
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    onGoToEmployeeCreate() {
      this.$router.push(routePaths.EMPLOYEE_CREATE);
      this.addBreadCrumbs(routePaths.EMPLOYEE_CREATE);
    },
    addBreadCrumbs(pathActive) {
      const breadCrumbs =
        DataBreadCrumbUtils.getBreadCrumbByPathActive(pathActive);
      this.setBreadcrumbs(breadCrumbs);
    },
    viewDetail(item) {
      this.$router.push({
        path: routePaths.EMPLOYEE_DETAIL,
        query: { id: item.id },
      });
      this.addBreadCrumbs(routePaths.EMPLOYEE_DETAIL);
    },
  },
};
</script>
<style src="@/styles/postOffice.scss" lang="scss">
</style>
